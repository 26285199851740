/* body{
  height: 100%;
}
html{
  height: 100%;
} */
/* .hfull{
  height: 100%;
} */
.hvh{
  height: 100vh!important;
  overflow: hidden;
}
body #root{
  height: auto;
  min-height: 100vh;
  /* max-width: 420px; */
}