p {
  margin: 0
}

.box-white {
  border-radius: 15px;
  background: #F4F4F4;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.05), -2px -2px 4px 0px #FFF;
}

.productHover {
  border-radius: 15px !important;
  border: none !important;
  height: 100%;
  position: relative;
  top: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.productHover:hover {
  -webkit-box-shadow: 0 0 19px 0 #00000029;
  box-shadow: 0 0 19px 0 #00000029;
  top: -5px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.setting-title-bar {
  padding: 5px 15px;
  border-radius: 48px;
  color: #fff
}

img {
  max-width: 100% !important;
}

.announcement-menu {
  max-height: 150px;
  position: relative;
  background-color: #fcfdff !important;
  -webkit-box-shadow: 1px -4px 6px 0px #0000001c;
  box-shadow: 1px -4px 6px 0px #0000001c;
  border-radius: 0 !important;
  border: none !important;
}

.item .transformHeader {
  -webkit-animation-name: tored;
  animation-name: tored;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.item .transformHeaderTransparent {
  -webkit-animation-name: totransparent;
  animation-name: totransparent;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.card-transparent {
  border: 1px solid rgba(255, 255, 255, 0.34);
  -webkit-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.13);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.13);
  width: 100%;
  min-height: 175px;
  border-radius: 15px;
  background: #ffffff52;
  left: 0;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px)
}

.card-color {
  border: 1px solid rgba(255, 255, 255, 0.34);
  -webkit-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.13);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.13);
  width: 100%;
  min-height: 175px;
  border-radius: 15px;
  background: linear-gradient(99deg, #00B09B 8.4%, #96C93D 98.03%);
  left: 0;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px)
}

.card-color-recipient {
  border: 1px solid rgba(255, 255, 255, 0.34);
  -webkit-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.13);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.13);
  width: 100%;
  min-height: 50px;
  border-radius: 15px;
  background: linear-gradient(99deg, #00B09B 8.4%, #96C93D 98.03%);
  left: 0;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px)
}

.card-color2 {
  border: 1px solid rgba(255, 255, 255, 0.34);
  -webkit-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.13);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.13);
  width: 100%;
  min-height: 50px;
  border-radius: 15px;
  background: #0EB393;
  left: 0;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px)
}

.card-color3 {
  border: 1px solid rgba(255, 255, 255, 0.34);
  -webkit-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.13);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.13);
  width: 100%;
  min-height: 50px;
  border-radius: 15px;
  background: #88C746;
  left: 0;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px)
}

.card-text {
  color: #FFF;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.10);
}

.typo-gray {
  color: #4E4E4E;
}

.subtotal-tapo {
  color: #717171 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

/* ///////// Text ////////// */

.txt_truncate {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.txt_truncate2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 2.86em;
}

.txt_truncate3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 2.86em;
}

.txt_truncate1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 2.86em;
}

/* ///////// Color ////////// */
.txt-gray {
  color: #858585;
}

/* ///////// Text Field ////////// */
.MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.MuiOutlinedInput-root {
  border-radius: 48px !important;
  -webkit-box-shadow: inset 2px 2px 6px 0 #00000026, 3px 4px 3px 0 #fff;
  box-shadow: inset 2px 2px 6px 0 #00000026, 3px 4px 3px 0 #fff
}

.MuiOutlinedInput-inputMarginDense {
  padding: 15px 20px !important
}

/* ///////// Button ////////// */
.button-theme {
  background: #2E523C;
  background-color: #2E523C;
  background: -o-linear-gradient(351deg, rgb(0, 134, 69) 0%, rgb(0, 93, 48) 100%);
  background: linear-gradient(99deg, rgb(0, 134, 69) 0%, rgb(0, 93, 48) 100%);
  color: #f4f4f4 !important;
  text-align: center;
}

.button-theme:hover {
  background: #50AB30;
  background: -o-linear-gradient(351deg, rgb(80, 171, 48) 0%, rgb(99, 217, 56) 100%);
  background: linear-gradient(99deg, rgb(80, 171, 48) 0%, rgb(99, 217, 56) 100%);
}

.button-fixed {
  width: 420px;
  max-width: 100%;
  position: -webkit-sticky;
  position: fixed;
}

.red-button-style {
  background: #008645 !important;
  color: #ffffff !important;
  cursor: pointer;
  text-align: center;
  border-radius: 48px !important;
  -webkit-box-shadow: 3px 2px 6px 0 #5feaf487, inset 1px 1px 3px 0 #ffffff8f !important;
  box-shadow: 3px 2px 6px 0 #5feaf487, inset 1px 1px 3px 0 #ffffff8f !important;
}

.starter-button-style {
  /* background: rgb(159,208,26); */
  background: -o-linear-gradient(34deg, rgb(43 166 68) 0%, rgb(21 188 55) 43%, rgb(8 173 40) 100%);
  background: linear-gradient(56deg, rgb(43 166 68) 0%, rgb(21 188 55) 43%, rgb(8 173 40) 100%);
  -webkit-box-shadow: 3px 2px 6px 0 #068103, inset 1px 1px 3px 0 #08ad5d;
  box-shadow: 3px 2px 6px 0 #068103, inset 1px 1px 3px 0 #08ad5d;
  cursor: pointer;
  border-radius: 20px;
  color: #fff;
  padding: 13px 28px;
  width: 80%;
  margin: -46px auto 0px;
  text-align: center;
}

/* ///////// Package ////////// */
.package-card {
  background: -o-linear-gradient(304deg, rgb(0, 134, 69) 0%, rgb(0, 93, 48) 100%);
  background: linear-gradient(146deg, rgb(0, 134, 69) 0%, rgb(0, 93, 48) 100%);
  height: 130px;
  background: '#222';
  border-radius: 15px;
}

.shadow-out {
  -webkit-box-shadow: inset 2px 2px 4px 0 hsla(128, 89%, 31%, 0.7), 3px 3px 4px 0 rgba(0, 0, 0, .19);
  box-shadow: inset 2px 2px 4px 0 hsla(128, 89%, 31%, 0.7), 3px 3px 4px 0 rgba(0, 0, 0, .19);
}

.converted-text p {
  margin: 0;
}

.mobile-width-fixed {
  position: fixed;
}

.fixed-header-padding {
  padding-top: 45px;
}

/* ///////// Dialog ////////// */
.mobile-width-fixed,
.mobile-width {
  width: 420px !important;
  max-width: 100%;
}

.MuiDialog-paperWidthSm {
  width: 350px !important;
  max-width: 80% !important;
}

.MuiDialog-paperWidthXs-51 {
  width: 350px !important;
  max-width: 80% !important;
}

.dialog-text p {
  margin: 0;
}

/*---------------------------------------------*/

.fs-12-g-b {
  font-size: 38px;
  color: '#4E4E4E';
  font-weight: 'bolder';
}

/* ///////// Slider ////////// */

#product_images img {
  max-width: 100% !important;
}

.wallet .awssld__container {
  padding-bottom: calc(var(--slider-height-percentage) * 0.9);
}

.wallet .awssld__content {
  border-radius: 15px;
}

.home .awssld__wrapper {
  border-radius: 15px;
}

.home .awssld__container {
  padding-bottom: calc(var(--slider-height-percentage) * 1) !important;
}

.partner .awssld__container {
  padding-bottom: calc(var(--slider-height-percentage) * 0.59);
}

.partner .awssld__content {
  background-color: transparent;
  border-radius: 15px;
}

.partner .awssld__wrapper {
  border-radius: 15px;
}

.partnerbanner {
  -o-object-fit: contain;
  object-fit: contain;
  display: block;
  width: 100%;
  border-radius: 15px;
}

/* .awssld__container{
    padding-bottom: calc(var(--slider-height-percentage) * 0.58);
  } */
.awssld__content {
  background-color: #008645;
}

.shop .awssld__container {
  padding-bottom: calc(var(--slider-height-percentage) * 0.215);
}

.awssld {
  --control-bullet-color: #2E523C !important;
  --control-bullet-active-color: #9FD01A !important;
}

.awssld__bullets {
  bottom: 5px !important;
  z-index: 99;
  /* position: absolute; */
  /* width: 100%; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}

/* ///////// Form ////////// */
.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #50AB30;
  will-change: opacity, transform;
  -webkit-box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #419024;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #419024;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #50AB30;
}

.cnyFrameTop {
  background-image: url(/images/cny/frame-top.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.cnyFrameBottom {
  background-image: url(/images/cny/frame-bottom.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}




.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

/* .orgchart{
    transform: scale(0.5);
  } */
.orgchart-container {
  border: 2px dashed #d9d9d9;
  margin: 10px 0 0;
}

/* .orgchart-container {
    display: flex;
    justify-content: center;
  } */
.slick-prev {
  left: -11px !important
}

.slick-next {
  right: -15px !important;
}

.slick-prev,
.slick-next {
  top: 33% !important
}

.slick-prev:before,
.slick-next:before {
  color: #c2c2c2 !important;
}

.dot_style {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  text-align: center;
  padding-top: 10px;
}

.dot_style li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0px 10px;
}

.dot_style li button {
  border: none;
  background: #D9D9D9;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 15px;
  width: 15px;
  border-radius: 7.5px;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.10) inset;
  filter: drop-shadow(1px 1px 3px #FFF);
}

.dot_style li.slick-active button {
  background-color: #67CEB6;
}

/* .dot_style li button:hover,
.dot_style li button:focus {
  background: rgba(247, 34, 34, 0.8);
  outline: 0;
} */

.image-gallery-content.fullscreen {
  top: 20vh;
  width: 420px;
  max-width: 100%;
  margin: 0 auto;
}

/* ///////// Animation ////////// */

@-webkit-keyframes totransparent {
  0% {
    background-color: #008645;
  }

  100% {
    background-color: transparent;
  }
}

@keyframes totransparent {
  0% {
    background-color: #008645;
  }

  100% {
    background-color: transparent;
  }
}

@-webkit-keyframes tored {
  0% {
    background-color: transparent;
  }

  100% {
    background-color: #008645;
  }
}

@keyframes tored {
  0% {
    background-color: transparent;
  }

  100% {
    background-color: #008645;
  }
}

/* ///////// Media Screen ////////// */

@media only screen and (max-width: 600px) {
  .shop img {
    height: 89px;
  }

  .shop .awssld__container {
    padding-bottom: calc(var(--slider-height-percentage) * 0.41);
  }
}